/*
 * JaCoCo Report Viewer, a web-based coverage report viewer
 * Copyright (C) 2018  Euxane TRAN-GIRARD
 *                     Adam NAILI
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';

export class CoverageListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingContent: null,
      coverageMap: null
    };
  }

  componentDidMount() {
    this._updateState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.fileName === prevProps.fileName &&
        this.props.sourceSet === prevProps.sourceSet &&
        this.props.coverage === prevProps.coverage) return;

    this._updateState();
  }

  _updateState() {
    if (!this.props.sourceSet || !(this.props.fileName in this.props.sourceSet)) {
      this.setState({ listingContent: null });
      return;
    }

    this._updateListingContent();
    this._updateCoverageMap();
  }

  _updateCoverageMap() {
    const coverageMap = this.props.coverage.reduce(function(map, line) {
      map[parseInt(line.$.nr)] = line.$;
      return map;
    }, {});

    this.setState({ coverageMap: coverageMap });
  }

  _updateListingContent() {
    this.props.sourceSet[this.props.fileName]
              .async('text')
              .then(content => this.setState({ listingContent: content }));
  }

  _lineCoverageString(coverage) {
    const instructionCount = parseInt(coverage.ci) + parseInt(coverage.mi);
    const branchCount = parseInt(coverage.cb) + parseInt(coverage.mb);
    return coverage.ci + '/' + instructionCount + ' instructions covered, ' +
           coverage.cb + '/' + branchCount + ' branches covered';
  }

  _renderNone() {
    return (<div>No source file provided.</div>);
  }

  _nonEmptyString(str) {
    return str ? str : ' '; // workaround for empty <pre> collapsing
  }

  _renderLine(lineContent, lineNumber) {
    if (!(lineNumber in this.state.coverageMap))
      return (<li key={lineNumber}><pre>{lineContent}</pre></li>);

    const coverage = this.state.coverageMap[lineNumber];
    const wellCovered = parseInt(coverage.mi) === 0 && parseInt(coverage.mb) === 0;

    return (
      <li key={lineNumber} well-covered={wellCovered.toString()} title={this._lineCoverageString(coverage)}>
        <pre>{lineContent}</pre>
      </li>
    );
  }

  _renderListing() {
    const lines = this.state.listingContent
                            .split('\n')
                            .map(this._nonEmptyString)
                            .map((line, index) => this._renderLine(line, index + 1));

    return (<div className="listing"><ol>{lines}</ol></div>);
  }

  render() {
    return this.state.listingContent ? this._renderListing() : this._renderNone();
  }
}
