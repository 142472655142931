/*
 * JaCoCo Report Viewer, a web-based coverage report viewer
 * Copyright (C) 2018  Euxane TRAN-GIRARD
 *                     Adam NAILI
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { CoverageListing } from './CoverageListing.js';

function renderRows(renderRowFunc, rows, inline) {
  const renderedRows = rows ? rows.map(renderRowFunc) : (<li>None.</li>);
  return (<ul inline-list={inline.toString()}>{renderedRows}</ul>);
}

export class SessionInfo extends Component {
  _renderRow(row) {
    const date = new Date(parseInt(row.start));
    return (<li key={row.id}>{row.id}: {date.toISOString()} ({row.dump - row.start} ms)</li>);
  }

  render() {
    return renderRows(row => this._renderRow(row.$), this.props.data, false);
  }
}

export class Counters extends Component {
  _renderRow(row) {
    const covered = parseInt(row.covered);
    const totalCount = covered + parseInt(row.missed);
    const wellCovered = covered === totalCount;
    return (<li key={row.type} well-covered={wellCovered.toString()}>{row.type}: {covered}/{totalCount}</li>);
  }

  render() {
    return renderRows(row => this._renderRow(row.$), this.props.data, this.props.inlineList !== undefined);
  }
}

export class GroupsCoverage extends Component {
  _renderChilds(row) {
    return row.group
      ? (<GroupsCoverage groups={row.group} />)
      : (<PackagesCoverage packages={row.package} />);
  }

  _renderRow(row) {
    return (
      <li key={row.$.name}>
        <span>{row.$.name}</span>
        <Counters data={row.counter} inlineList="true" />
        {this._renderChilds(row)}
      </li>
    )
  }

  render() {
    return renderRows(row => this._renderRow(row), this.props.groups, false);
  }
}

export class PackagesCoverage extends Component {
  _renderRow(row) {
    return (
      <li key={row.$.name}>
        <span>{row.$.name}</span>
        <Counters data={row.counter} inlineList="true" />
        <ClassesCoverage classes={row.class} />
      </li>
    )
  }

  render() {
    return renderRows(this._renderRow, this.props.packages, false);
  }
}

class ClassesCoverage extends Component {
  _renderRow(row) {
    const counters = row.counter.filter(counter => counter.$.type !== 'CLASS');
    return (
      <li key={row.$.name}>
        <span>{row.$.name}</span>
        <Counters data={counters} inlineList="true" />
        <MethodsCoverage methods={row.method} />
      </li>
    )
  }

  render() {
    return renderRows(this._renderRow, this.props.classes, false);
  }
}

class MethodsCoverage extends Component {
  _renderRow(row) {
    const counters = row.counter.filter(counter => counter.$.type !== 'METHOD');
    const method = row.$.name + row.$.desc + ':' + row.$.line;
    return (
      <li key={method}>
        <span>{method}</span>
        <Counters data={counters} inlineList="true" />
      </li>
    )
  }

  render() {
    return renderRows(this._renderRow, this.props.methods, false);
  }
}

export class GroupsSourceCoverage extends Component {
  _renderChilds(row) {
    return row.group
     ? (<GroupsSourceCoverage groups={row.group} sourceSet={this.props.sourceSet} />)
     : (<PackagesSourceCoverage packages={row.package} sourceSet={this.props.sourceSet} />);
  }

  _renderRow(row) {
    return (
      <li key={row.$.name}>
        <span>{row.$.name}</span>
        {this._renderChilds(row)}
      </li>
    )
  }

  render() {
    return renderRows(row => this._renderRow(row), this.props.groups, false);
  }
}

export class PackagesSourceCoverage extends Component {
  _renderRow(row) {
    return (
      <li key={row.$.name}>
        <span>{row.$.name}</span>
        <SourcesCoverage package={row.$.name} 
                         packageSourceFiles={row.sourcefile}
                         sourceSet={this.props.sourceSet} />
      </li>
    )
  }

  render() {
    return renderRows(row => this._renderRow(row), this.props.packages, false);
  }
}

class SourcesCoverage extends Component {
  _renderRow(row) {
    const fileName = this.props.package + '/' + row.$.name;
    return (
      <li key={fileName}>
        <span>{fileName}</span>
        <CoverageListing fileName={fileName}
                         sourceSet={this.props.sourceSet}
                         coverage={row.line} />
      </li>
    )
  }

  render() {
    return renderRows(row => this._renderRow(row), this.props.packageSourceFiles, false);
  }
}
