/*
 * JaCoCo Report Viewer, a web-based coverage report viewer
 * Copyright (C) 2018  Euxane TRAN-GIRARD
 *                     Adam NAILI
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { Counters, SessionInfo, GroupsCoverage, PackagesCoverage, GroupsSourceCoverage, PackagesSourceCoverage } from './listComponents.js';

export class Report extends Component {  
  _renderAppInfo() {
    return (
      <div>
        Copyright &copy; 2018 Euxane TRAN-GIRARD, Adam NAILI - <a href="https://cgit.euxane.net/jacoco-viewer/about/">Sources</a>
      </div>
    );
  }

  _renderNone() {
    return (
      <div>
        <div>Please provide a JaCoCo XML report file to visualise.</div>
        <br/>
        {this._renderAppInfo()}
      </div>
    );
  }

  _renderCoverageTree() {
    return this.props.report.group
      ? (<GroupsCoverage groups={this.props.report.group} />)
      : (<PackagesCoverage packages={this.props.report.package} />);
  }

  _renderSourceCoverage() {
    return this.props.report.group
      ? (<GroupsSourceCoverage groups={this.props.report.group} sourceSet={this.props.sourceSet} />)
      : (<PackagesSourceCoverage packages={this.props.report.package} sourceSet={this.props.sourceSet} />);
  }

  _renderReport() {
    return (
      <div>
        <h2>Viewing report: "{this.props.report.$.name}"</h2>

        <section>
          <h3>Session info</h3>
          <SessionInfo data={this.props.report.sessioninfo} />
        </section>

        <section>
          <h3>Global coverage</h3>
          <Counters data={this.props.report.counter} />
        </section>

        <section>
          <h3>Coverage tree</h3>
          {this._renderCoverageTree()}
        </section>

        <section>
          <h3>Source coverage</h3>
          {this._renderSourceCoverage()}
        </section>
      </div>
    );
  }

  render() {
    return this.props.report ? this._renderReport() : this._renderNone();
  }
}
