/*
 * JaCoCo Report Viewer, a web-based coverage report viewer
 * Copyright (C) 2018  Euxane TRAN-GIRARD
 *                     Adam NAILI
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { Parser } from 'xml2js';
import JSZip from 'jszip';

import { Report } from './Report.js';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: null,
      sourceSet: null,
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.error(error, info);
  }

  _useReportFile(file) {
    const fileReader = new FileReader();
    fileReader.onloadend = readEvent => this._useReport(readEvent.target.result);
    fileReader.readAsText(file);
  }

  _useReport(xmlString) {
    this.setState({ hasError: false });
    const xmlParser = new Parser();
    xmlParser.parseString(xmlString, (_, result) => this.setState({ report: result.report }));
  }

  _useSourceArchive(file) {
    this.setState({ hasError: false });
    JSZip.loadAsync(file).then(zip => this.setState({ sourceSet: zip.files }));
  }

  _renderError() {
    return (
      <span>
        Something went wrong while rendering the report.
        Are the provided files valid?
      </span>
    );
  }

  _renderReport() {
    return this.state.hasError ? this._renderError(): (<Report report={this.state.report} sourceSet={this.state.sourceSet} />);
  }

  render() {
    return (
      <div className="App">
        <h1>JaCoCo Report Viewer</h1>

        <div>
          <label htmlFor="reportFile">XML report: </label>
          <input id="reportFile" type="file" accept=".xml" onChange={event => this._useReportFile(event.target.files[0])} />
        </div>

        <div>
          <label htmlFor="sourceJar">Source JAR: </label>
          <input id="sourceJar" type="file" accept=".jar" onChange={event => this._useSourceArchive(event.target.files[0])} />
        </div>

        <hr />
        {this._renderReport()}
      </div>
    );
  }
}

export default App;
